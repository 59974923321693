import React, { useEffect, useState } from "react";
import ChatList from "./ChatList";
import ChatBox from "./ChatBox";
import ChatHeader from "./ChatboxHeader";
import "./ChatApp.css"; // Import CSS for styling
import { apiCall } from "../Api/Api";
import useSnackbar from "../Snackbar/UseSnackbar";

const chatData = [
    { id: 1, name: "John Doe", messages: ["Hello there!", "Hope you're doing well!"] },
    { id: 2, name: "Jane Smith", messages: ["Good morning!", "Can you assist me with my account?"] },
    { id: 3, name: "Bob Johnson", messages: ["Hey!", "I have a question about my recent order."] },
    { id: 4, name: "Alice Cooper", messages: ["Hi!", "I'm wondering about the delivery status."] },
    { id: 5, name: "Steve Rogers", messages: ["Good afternoon!", "Need help with a return, please."] },
    { id: 6, name: "Tony Stark", messages: ["Yo!", "Can you update me on my shipment?"] },
    { id: 7, name: "Bruce Wayne", messages: ["Hello!", "I’m having issues with the website."] },
    { id: 8, name: "Clark Kent", messages: ["Hey!", "What's the refund policy?"] },
    { id: 9, name: "Diana Prince", messages: ["Hi!", "Could you assist with my payment issue?"] },
    { id: 10, name: "Natasha Romanoff", messages: ["Good evening!", "I need help tracking my order."] },
    { id: 11, name: "Peter Parker", messages: ["Hey!", "I’m confused about the shipping fees."] },
];



function ChatApp() {
    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

    const [selectedChatId, setSelectedChatId] = useState(null);
    const [messages, setMessages] = useState(chatData);
    const [isChatOpen, setIsChatOpen] = useState(false); // For toggling chatbox

    const [mainChatList, setMainChatList] = useState([]);


    const selectChat = (id, divReference) => {
        setSelectedChatId(id);
        setIsChatOpen(true); // Open the chatbox when a person is selected
        // setTimeout(() => {
        if (divReference.current) {
            divReference.current.scrollTo({
                top: divReference.current.scrollHeight,
                behavior: "smooth", // This makes it smooth AF
            });
        }
        // }, 120);
    };

    const toggleChatbox = () => {
        setIsChatOpen(!isChatOpen); // Toggle chatbox open/closed
    };

    const sendMessage = (newMessage) => {
        if (selectedChatId === null || !newMessage.trim()) return;

        const updatedMessages = messages.map((chat) =>
            chat.id === selectedChatId
                ? { ...chat, messages: [...chat.messages, newMessage] }
                : chat
        );

        setMessages(updatedMessages);
    };

    const getChats = async (lastchatid) => {
        const response = await apiCall({
            limit: 50,
            lastchatid,
        }, `whatsapp/wati_first_chatload`, "post");
        if (response.success) {
            console.log("chatload" + JSON.stringify(response.data));
            if (lastchatid) {
                setMainChatList((prevChats) => [...prevChats, ...response.data]);
            } else {
                setMainChatList(response.data);
            }
            SuccessSnackbar(response.message);
        }
        else {
            ErrorSnackbar(response.message);
        }
    }

    const loadMoreChats = async (lastchatid) => {
        await getChats(lastchatid);
    }

    useEffect(() => {
        getChats();
    }, [])

    return (
        <></>
    );
}

// function ChatApp() {
//     const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

//     const [selectedChatId, setSelectedChatId] = useState(null);
//     const [messages, setMessages] = useState(chatData);
//     const [isChatOpen, setIsChatOpen] = useState(false); // For toggling chatbox

//     const [mainChatList, setMainChatList] = useState([]);


//     const selectChat = (id, divReference) => {
//         setSelectedChatId(id);
//         setIsChatOpen(true); // Open the chatbox when a person is selected
//         // setTimeout(() => {
//         if (divReference.current) {
//             divReference.current.scrollTo({
//                 top: divReference.current.scrollHeight,
//                 behavior: "smooth", // This makes it smooth AF
//             });
//         }
//         // }, 120);
//     };

//     const toggleChatbox = () => {
//         setIsChatOpen(!isChatOpen); // Toggle chatbox open/closed
//     };

//     const sendMessage = (newMessage) => {
//         if (selectedChatId === null || !newMessage.trim()) return;

//         const updatedMessages = messages.map((chat) =>
//             chat.id === selectedChatId
//                 ? { ...chat, messages: [...chat.messages, newMessage] }
//                 : chat
//         );

//         setMessages(updatedMessages);
//     };

//     const getChats = async (lastchatid) => {
//         const response = await apiCall({
//             limit: 50,
//             lastchatid,
//         }, `whatsapp/wati_first_chatload`, "post");
//         if (response.success) {
//             console.log("chatload" + JSON.stringify(response.data));
//             if (lastchatid) {
//                 setMainChatList((prevChats) => [...prevChats, ...response.data]);
//             } else {
//                 setMainChatList(response.data);
//             }
//             SuccessSnackbar(response.message);
//         }
//         else {
//             ErrorSnackbar(response.message);
//         }
//     }

//     const loadMoreChats = async (lastchatid) => {
//         await getChats(lastchatid);
//     }

//     useEffect(() => {
//         getChats();
//     }, [])

//     return (
//         <div className="chat-app">
//             <div className={`chatbox-container ${isChatOpen ? "open" : "collapsed"}`}>
//                 <ChatHeader toggleChatbox={toggleChatbox} isChatOpen={isChatOpen} />
//                 <div style={{ display: "flex" }}>
//                     <div>
//                         <ChatList chats={mainChatList} onSelectChat={selectChat} selectedChatId={selectedChatId} loadMoreChats={loadMoreChats} />
//                     </div>
//                     <div style={{ overflowY: "auto", maxHeight: "400px" }}>
//                         {isChatOpen && (
//                             <ChatBox
//                                 selectedChat={messages.find((chat) => chat.id === selectedChatId)}
//                                 onSendMessage={sendMessage}
//                             />
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

export default ChatApp;
