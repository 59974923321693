import { Divider } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
const moment = require('moment');

const ChatList = ({ chats, onSelectChat, selectedChatId, loadMoreChats }) => {
    const divRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleScroll = () => {
        const bottom =
            divRef.current.scrollHeight - divRef.current.scrollTop ===
            divRef.current.clientHeight;
        if (bottom && !isLoading) {
            setIsLoading(true);
            loadMoreChats(chats[chats.length - 1].id); // Function to load more chats
        }
    };

    useEffect(() => {
        const currentDiv = divRef.current;
        if (currentDiv) {
            currentDiv.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (currentDiv) {
                currentDiv.removeEventListener("scroll", handleScroll);
            }
        };
    }, [chats, isLoading]);

    useEffect(() => {
        if (isLoading) {
            // Simulate API delay for loading more chats
            setTimeout(() => setIsLoading(false), 1000);
        }
    }, [isLoading]);

    return (
        <div className="chat-list" ref={divRef} style={{ height: "400px", overflowY: "scroll" }}>
            {chats.map((chat) => (
                <div key={chat.id}>
                    <div
                        className="chat-item"
                        onClick={() => onSelectChat(chat.id)}
                        style={{
                            border: selectedChatId === chat.id ? "1px solid blue" : "none",
                        }}
                    >
                        <div style={{ fontWeight: "600", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {chat.name.length > 20 ? chat.name.substring(0, 20) + "..." : chat.name}
                            </div>
                            <div style={{ fontWeight: "normal", fontSize: "10px", display: "flex", justifyContent: "flex-end" }}>
                                {chat?.ticket?.assignedFullName?.length > 20 ? chat.ticket?.assignedFullName?.substring(0, 20) + "..." : chat?.ticket?.assignedFullName || ""}
                            </div>
                        </div>

                        <div style={{ fontSize: "12px" }}>
                            {chat.text.length > 30 ? chat.text.substring(0, 30) + "..." : chat.text}
                        </div>
                        <div style={{ fontSize: "10px", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {chat.ticket.status == 1 ? (moment().add(4, 'hours')).isBefore(moment(chat.replyDeadline, "DD/MM/YYYY, hh:mm:ss a")) ? (<div style={{ borderRadius: "5px", color: "white", padding: "3px", backgroundColor: "#198b58" }}>open</div>) : (<div style={{ borderRadius: "5px", color: "white", backgroundColor: "#c94749", padding: "3px" }}>expired</div>) : chat.ticket.status == 2 ? (<div style={{ borderRadius: "5px", color: "white", backgroundColor: "#198b58", padding: "3px" }}>solved</div>) : chat.ticket.status == 3 ? (<div style={{ borderRadius: "5px", color: "white", backgroundColor: "#d4d459", padding: "3px" }}>pending</div>) : null}
                            </div>
                            <div>
                                {chat.lastIncomingDate.substring(13, 23)}
                            </div>
                        </div>
                    </div>

                    {/* Divider outside the chat-item div, but still within the same parent div */}
                    <Divider
                        style={{
                            backgroundColor: "black",
                            height: "2px",
                            width: "100%",
                            borderRadius: "2px",
                        }}
                    />
                </div>
            ))}

            {isLoading && <div>Loading more chats...</div>}
        </div>
    );
};

export default ChatList;
