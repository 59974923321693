import React, { useEffect, useCallback, useState, useRef, useContext, } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Box from "@mui/material/Box";
import green_tick from "../assets/images/green_tick.png";
import yellow_error from "../assets/images/yellow_error.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DeleteOutlineRounded } from "@mui/icons-material";
import { Autocomplete, Button, CircularProgress, CssBaseline, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip, } from "@mui/material";
import { API_DOMAIN, eventStatusMap, string_sanitizing_regex } from "../components/constants";
import { useNavigate } from "react-router-dom";
import whatsapp_logo from "../assets/images/whatsapp-96.png";
import EditIcon from "@mui/icons-material/Edit";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ReactTags } from "react-tag-autocomplete";
import styled from "styled-components";
import * as XLSX from "xlsx";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ImportExport from "@mui/icons-material/ImportExport";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import LoadingComponent from "../components/LoadingComponent/Loading";
import socket from "../components/socket";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { LicenseInfo } from "@mui/x-license-pro";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import ExcelLogo from "@mui/icons-material/ExcelLogo";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Checkbox } from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { Paper } from "@mui/material";
import { TableBody } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { bouncy } from "ldrs";
import CloseIcon from '@mui/icons-material/Close';
import SellIcon from "@mui/icons-material/Sell";
import useSnackbar from "../components/Snackbar/UseSnackbar";
import CustomToolbarSelect from "../components/CustomToolbar/CustomToolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsModal from "../components/SettingsModal/SettingsModal";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { AuthContext } from "../components/AuthContext";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
bouncy.register();

LicenseInfo.setLicenseKey(
  "e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const moment = require("moment");

// The MUI alert definition
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CustomSpan = styled.span`
  font-size: 22px;
  cursor: pointer;

  &:hover {
    background-color: rgb(220, 220, 220); /* Change the color on hover */
    border-radius: 350px;
  }
`;
var phoneToNameMap = [];
var supplierIdToName = [];

// Displaying the Users to the admin and their information
function Queryset() {
  const head = {
    headers: {
      accessToken: localStorage.getItem("accessToken")
    }
  };
  const { authState } = useContext(AuthContext);


  const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

  let navigate = useNavigate();

  // The basic datasets
  const [querySet, setquerySet] = useState([]);
  const [usersSet, setUsersSet] = useState([]);
  const [makeSet, setmakeSet] = useState([]);
  const [brandSet, setbrandSet] = useState([]);
  const [modelSet, setModelSet] = useState([]);
  const [supplierSet, setsupplierSet] = useState([]);
  const [regionDataSet, setRegionDataSet] = useState([]);
  const [cus_name_set, set_cus_name_set] = useState([]);
  const [queryStatusSet, setQueryStatusSet] = useState([]);
  const [costPriceSet, setCostPriceSet] = useState([]);
  const [priceHistory, setPriceHistory] = useState([]);

  const [loading, setLoading] = useState(true);

  //Search parameters
  const [searchStatus, setSearchStatus] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  //Custom modal to add stuff
  const [modalHeading, setModalHeading] = useState("");
  const [modal_data, set_modal_data] = useState("");

  //form data
  const [make_data, set_make_data] = useState([]);
  const [brand_data, set_brand_data] = useState(null);
  const [queryidEdit, set_queryid] = useState(null);
  const [partNumber, setPartNumber] = useState("");
  const [model_data, set_model_data] = useState(null);
  const [model_details_data, set_model_details_data] = useState("");
  const [key_to_reset, set_key_to_reset] = useState(null);
  const [cus_name, set_cus_name] = useState("");
  const [year_data, set_year_data] = useState(null);
  const [chassisno_data, set_chassisno_data] = useState("");
  const [cylinder_data, set_cylinder_data] = useState("");
  const [bodypart_data, set_bodypart_data] = useState("");
  const [phone_data, set_phone_data] = useState("");
  const [region_data, set_region_data] = useState("");
  const [file_data, set_file_data] = useState([]);
  const [WhatsappStatus, setWhatsappStatus] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editWhatsapp, setEditWhatsapp] = useState(false);
  const [suppCount, setSuppCount] = useState(0);
  const [queryModalTitle, setQueryModalTitle] = useState("Create new Query");
  const [queryMode, setQueryMode] = useState("");

  //misc data
  const [waStatus, setwaStatus] = useState({ success: [], failed: [] });
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(false);

  //references
  const file_DataRef = useRef(null);
  const queryDateRef = useRef(null);

  //Tab Focus to know if to send desktop notifications
  const [isTabFocused, setIsTabFocused] = useState(
    document.visibilityState === "visible"
  );
  const isTabFocusedRef = useRef(isTabFocused);

  const [expandedRows, setExpandedRows] = useState({});

  const handleExpandClick = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  // These are for the Modals
  //Query adding modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setIsQsDisabled(false);
    setSuppCount(0);
    if (WhatsappStatus.status === "logged_in") {
      reload_customers();
      setOpen(true);
    } else alert("Please resolve WhatsApp Bot issues before making a query.");
  };
  const handleClose = () => {
    setPriceHistory([]);
    reloadQuerySet();
    // document.getElementById("sup_count").style.display = "none";
    set_make_data([]);
    setModelSet([]);
    set_brand_data(null);
    setPartNumber("");
    set_year_data(null);
    set_model_data(null);
    set_model_details_data("");
    set_chassisno_data("");
    set_cylinder_data("");
    set_bodypart_data("");
    set_phone_data("");
    set_region_data("");
    set_file_data(null);
    setEdit(false);
    set_cus_name("");
    setSuppCount(0);
    setIsQsDisabled(false);
    setEdit(false)
    setEditWhatsapp(false)
    set_queryid(null)
    setQueryMode("")
    setTimeout(() => {
      if (file_DataRef.current) {
        file_DataRef.current.disabled = true;
      }
    }, 0);
    setQueryModalTitle("Create new Query");
    setOpen(false);
  };

  const send_wati_general_message = async (phone, message) => {
    try {
      var response = await axios.post(API_DOMAIN + "/whatsapp/send_wati_general_message", { phone: phone, message: message, user: authState.id, }, head);
      if (response.data.success) {
        SuccessSnackbar("Message sent successfully!");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      alert("Failed to send message! Try again later\n", error.message);
    }
  };

  const handleSendWatiLink = async () => {
    try {
      var message = [];
      const phone = dataLink.phone;

      message.push(`Dear ${currentQueryData.cus_name}, \nPlease find the link-to-pay link generated below, to proceed with the payment.\n\n${theLink}\n\nDont Forget to share the screenshot.\n\nThank you for choosing UZDpart!`);

      var response = await send_wati_general_message(phone, message);

      if (response) {
        document.getElementById("link_gen_send_wati").style.display = "none";
        setOpenLinkGen(false);
      } else {
        ErrorSnackbar("Failed to send message! Try again later.");
      }
    } catch (error) {
      ErrorSnackbar("Failed to send message! Try again later\n", error.message);
    }
  };

  //Link generation model
  const [dataLink, setDataLink] = useState({});
  const [openLinkGen, setOpenLinkGen] = useState(false);
  const handleOpenLinkGen = (data) => {
    setDataLink(data);
    setLinkTitle(`${data.body_part}`);
    setLinkDescription(`(${data.make} - ${data.model})`);
    setLinkAmount(data.price_given);
    if (data.status != "Confirmed") {
      if (
        window.confirm('This query is not yet set to "Confirmed". Do you want to proceed?\nThis will change the status to "Confirmed & Paid" automatically')
      ) {
        setOpenLinkGen(true);
      }
    } else {
      setOpenLinkGen(true);
    }
  };
  const handleCloseLinkGen = () => {
    document.getElementById("link_gen_send_wati").style.display = "none";
    setOpenLinkGen(false);
    setDataLink({});
    setTheLink("");
  };

  const handleLinkGen = async () => {
    if (!linkTitle) {
      WarningSnackbar("Please enter a valid Title");
      return;
    }
    if (!linkAmount) {
      WarningSnackbar("Please enter a valid Amount");
      return;
    }
    try {
      var response = await axios.post(
        API_DOMAIN + "/whatsapp/get_payByLink_link",
        {
          query_id: dataLink.query_id,
          title: linkTitle,
          description: linkDescription,
          amount: linkAmount,
          processing_fee: linkFeePercent,
        },
        head
      );
      if (response.data.success) {
        setTheLink(response.data.data.payment_url);
        const dataLink_copy = {
          ...dataLink,
          moredetails: {
            ...dataLink.moredetails,
            payment_details: response.data.data,
          },
        };
        setDataLink(dataLink_copy);

        SuccessSnackbar("PayByLink generated successfully!");
        document.getElementById("link_gen_send_wati").style.display = "flex";

        // await axios.post(API_DOMAIN + "/whatsapp/update_querysetV2", dataLink);
      }
    } catch (error) {
      ErrorSnackbar(
        "Failed to generate PayByLink link! Try again later\n",
        error.message
      );
    }
  };

  const [linkTitle, setLinkTitle] = useState("");
  const [linkDescription, setLinkDescription] = useState("");
  const [linkAmount, setLinkAmount] = useState("");
  const [linkFeePercent, setLinkFeePercent] = useState("");
  const [isQsDisabled, setIsQsDisabled] = useState(false);

  const setLinkTitleHandler = (title) => {
    if (linkTitle.length <= 50) {
      if (string_sanitizing_regex.test(title)) {
        setLinkTitle(title);
        return;
      }
    }
    InfoSnackbar("Title can't be more than 50 characters and can't contain special characters, you can use hyphens, commas and periods only!");
  };

  const setLinkDescriptionHandler = (title) => {
    if (linkDescription.length <= 100) {
      if (string_sanitizing_regex.test(title)) {
        setLinkDescription(title);
        return;
      }
    }
    InfoSnackbar("Dedscription can't be more than 100 characters and can't contain special characters, you can use hyphens, commas and periods only!");
  };

  const handleResetField = (fieldName) => {
    switch (fieldName) {
      case "linkTitle":
        setLinkTitle("");
        break;
      case "linkDescription":
        setLinkDescription("");
        break;
      case "linkAmount":
        setLinkAmount("");
        break;
      case "linkFeePercent":
        setLinkFeePercent("");
        break;
    }
  }

  const loading_bouncy = () => {
    return (
      <div>
        {/* Other components or elements */}
        <l-bouncy size="45" speed="1.5" color="blue"></l-bouncy>
      </div>
    );
  };

  const [loadLink, setLoadLink] = useState(true);

  const [theLink, setTheLink] = useState("");

  //Whatsapp Bot Query sent status viewing modal
  const [open_stat, setOpenStat] = useState(false);
  const handleOpenStat = (value) => {
    var wa_stat = value.moredetails;

    // Add name to failed entries
    wa_stat.failed = wa_stat.failed.map((entry) => ({
      ...entry,
      name: phoneToNameMap[entry.number],
    }));

    // Convert success entries to objects with name and number
    wa_stat.success = wa_stat.success.map((number) => ({
      number: number,
      name: phoneToNameMap[number],
    }));
    setwaStatus(wa_stat);
    setOpenStat(true);
  };
  const handleCloseStat = () => {
    setOpenStat(false);
  };

  //Adding stuff to DB modal
  const [open_add_modal, setAddModalState] = useState(false);
  const handleOpenAddModal = (title) => {
    if (
      title == "Add New Model" &&
      (brand_data == null ||
        brandSet.some((element) =>
          element["label"] == "" ? false : element["label"] == brand_data
        ))
    ) {
      ErrorSnackbar("Please select a 'Make' first, to add a new model");
      return;
    } else {
      setModalHeading(
        title == "Add New Model"
          ? "Add New '" + brand_data.label + "' Model"
          : title
      );
      set_modal_data("");
      setAddModalState(true);
    }
  };
  const handleCloseAddModal = () => {
    set_modal_data("");
    setModalHeading("");
    setAddModalState(false);
  };

  //Settings modal states
  const [open_settings, setSettings] = useState(false);
  const handleOpenSettingsModal = () => {
    setSettings(true);
  };
  const handleCloseSettingsModal = () => {
    setSettings(false);
  };

  //Settings modal states
  const [currentQueryData, setCurrentQueryData] = useState({});
  const [openQuickReplies, setOpenQuickReplies] = useState(false);
  const handleOpenQuickReplies = (data) => {
    setOpenQuickReplies(true);
    setCurrentQueryData(data);
  };
  const handleCloseQuickReplies = () => {
    setOpenQuickReplies(false);
  };

  const [openQuickRepliesEdit, setOpenQuickRepliesEdit] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const handleOpenQuickRepliesEdit = (templateId) => {
    if (templateId) {
      const foundTemplate = templateTemp.find(
        (template) => template.id === templateId
      );
      if (foundTemplate) {
        var processedText = foundTemplate.text.replaceAll(
          "{{name}}",
          currentQueryData.cus_name
        );

        processedText = processedText.replaceAll("{{query_id}}", currentQueryData.query_id);
        processedText = processedText.replaceAll("{{partname_value}}", currentQueryData.body_part);
        processedText = processedText.replaceAll("{{price_value}}", currentQueryData.price_given);
        processedText = processedText.replaceAll("{{make}}", currentQueryData.make);
        processedText = processedText.replaceAll("{{number}}", currentQueryData.phone);
        processedText = processedText.replaceAll("{{operator.first_name}}", authState.name);
        setReplyMessage(processedText); // Assign the processed text directly to state
      }
    }
    setTimeout(() => {
      setOpenQuickRepliesEdit(true);
    }, 0);
  };
  const handleCloseQuickRepliesEdit = () => {
    setReplyMessage("");
    setOpenQuickRepliesEdit(false);
  };

  const search_quickresponses = (event) => {
    var value = event.target.value;
    var temp = templates.filter((data) => {
      return (
        data.name.toLowerCase().includes(value.toLowerCase()) ||
        data.text.toLowerCase().includes(value.toLowerCase())
      );
    });
    setTemplatesTemp(temp);
  };

  const handleSendWatiTemplate = async () => {
    try {
      // var message = [];
      const phone = currentQueryData.phone;

      // message.push(replyMessage);

      var response = await send_wati_general_message(phone, replyMessage);

      if (response) {
        SuccessSnackbar("Message sent successfully!");
        setReplyMessage("");
        handleCloseQuickRepliesEdit();
      } else {
        ErrorSnackbar("Failed to send message! Try again later.");
      }
    } catch (error) {
      ErrorSnackbar(
        "Failed to send message! Try again later\n",
        error.message
      );
    }
  };

  //handling onclicks
  const handling_setquerySet = (data) => {
    console.log("data = " + JSON.stringify(data));

    const datasetMap = {};
    const initialPriceValues = {};

    data.forEach((dataset) => {
      dataset.price_percentage =
        (
          ((dataset.price_given -
            dataset.best_price_received -
            dataset.delivery_cost ?? 0) /
            dataset.best_price_received) *
          100
        ).toFixed(2) === "NaN"
          ? 0
          : (
            ((dataset.price_given -
              dataset.best_price_received -
              dataset.delivery_cost ?? 0) /
              dataset.best_price_received) *
            100
          ).toFixed(2);

      datasetMap[dataset.query_id] = dataset;

      initialPriceValues[dataset.query_id] = {
        best_price_received: dataset.best_price_received || 0,
        price_percentage: dataset.price_percentage || 0,
        price_given: dataset.price_given || 0,
        status: dataset.status,
        delivery_cost: dataset.delivery_cost
          ? { DeliveryCost: dataset.delivery_cost }
          : { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 },
        statusValue:
          dataset.status == "postponed"
            ? new Date(dataset?.moredetails?.statusValue)
            : dataset?.moredetails?.statusValue || null,
        remarks: dataset?.moredetails?.remarks || "",
      };
    });

    setquerySet(data);
    setQueryDataMap(datasetMap);
    setPriceValues(initialPriceValues);
  };
  const handleQueryValueChange = (queryId, field, value) => {
    setQueryDataMap({
      ...queryDataMap,
      [queryId]: {
        ...queryDataMap[queryId],
        [field]: value,
      },
    });
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    var final_files = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        final_files.push(base64String);
      };
      reader.readAsDataURL(files[i]);
    }
    set_file_data(final_files);
  };
  const handleSubmit = () => {
    //split the below if condition to check for each field
    if (make_data.length == 0) {
      WarningSnackbar("Please Select one Supplier minimum");
      return;
    }
    if (!brand_data) {
      WarningSnackbar("Please Select a Make");
      return;
    }
    if (!model_data) {
      WarningSnackbar("Please Select a Model");
      return;
    }
    if (!bodypart_data) {
      WarningSnackbar("Please Enter a PartName");
      return;
    }
    if (edit ? false : !file_data) {
      WarningSnackbar("Please Select an Image");
      return;
    }
    if (!year_data) {
      WarningSnackbar("Please Select a Year");
      return;
    }
    if (!brand_data) {
      WarningSnackbar("Please Select a Brand");
      return;
    }
    if (!phone_data) {
      WarningSnackbar("Please add customer mobile number");
      return;
    }
    if (chassisno_data) {
      if (chassisno_data.length < 17) {
        WarningSnackbar("Please Enter a Valid Chassis Number");
        return;
      }
    }

    if (schedule_wati) {
      window.confirm("Are you sure to schedule auto-replies for this query?") ? setschedule_wati(true) : setschedule_wati(false)
    }

    schedule_wati ? InfoSnackbar("Scheduling auto-replies for this query...") : InfoSnackbar("Skipping auto-replies for this query...");

    // Proceed with your form submission or further processing
    const formData = {};
    formData.make = make_data;
    formData.part_number = partNumber;
    formData.brand = brand_data;
    formData.model = model_data;
    formData.model_details = model_details_data.toString().trim();
    formData.year = year_data ? dayjs(year_data).format("YYYY") : "";
    formData.chassisNo = chassisno_data.toString().trim();
    formData.cylinders = cylinder_data.toString().trim();
    formData.bodypart = bodypart_data.toString().trim();
    formData.phone = phone_data.toString().trim();
    formData.region = region_data.toString().trim();
    formData.cus_name = cus_name.toString().trim();
    formData.image = file_data;
    formData.date = new Date().toISOString().substring(0, 10);
    formData.parallel = parallel;
    formData.edit = edit;
    formData.user = authState.id;
    formData.user_name = authState.name;
    formData.schedule_wati = schedule_wati;
    formData.queryMode = queryMode;
    formData.queryidEdit = queryidEdit;


    InfoSnackbar("Sending query....");
    setIsQsDisabled(true);
    axios.post(API_DOMAIN + "/whatsapp/add_querysetV2", { formData, api_secret: "uzdpart" }, head)
      .then((res) => {
        if (res.data.success) {
          setPriceHistory([]);
          setIsQsDisabled(false);
          if (schedule_wati) SuccessSnackbar("Auto-replies Scheduled Successfully!");
          if (isTabFocusedRef.current) {
            SuccessSnackbar("Query ID " + res.data.data.query_id + " processed successfully!");
            //this is to initiate the automatic message sending to WATI 

            // window.confirm("DO you want to send the 'Query ID details' message to WATI?")
          } else {
            showNotification("Query (" + res.data.data.query_id + ") Succeeded", "Query with ID " + res.data.data.query_id + " has been processed successfully!", null);
          }
          handleClose();
        } else {
          if (isTabFocusedRef.current) {
            ErrorSnackbar("Query ID " + res.data.data.query_id + " Failed to Process!\nPlease try again");
            ErrorSnackbar(res.data.message);
          } else {
            showNotification("Query (" + res.data.data.query_id + ") Failed", "Query with ID " + res.data.data.query_id + " has Failed to process!\nPlease try again later.", null);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorSnackbar("Failed to process Queryset! Try again later\n", error.message);
        setIsQsDisabled(false);
        document.getElementById("send_query_btn").innerHTML = "Try Again...";
      }).finally(() => {
        // Enable the button again whether success or failure
        setIsQsDisabled(false);
        document.getElementById("send_query_btn").innerHTML = "Try Again...";
      });
  };
  const handleQueryEdit = (data, mode = "duplicate") => {
    setEdit(true);
    if (data.phone)
      setTimeout(() => {
        handleCusMobChange(data.phone);
      }, 0);
    set_make_data([]);
    setModelSet([]);
    try { data.sup_set = JSON.parse(data.sup_set); } catch (error) { }

    set_queryid(data.query_id)
    set_make_data(data.sup_set);
    setPartNumber(data.part_number || "");
    set_brand_data(data.make);
    load_models(data.make);
    set_model_data(data.model);
    set_key_to_reset(dayjs().format("YYYY-MM-DD"));
    set_model_details_data(data.model_details);
    set_bodypart_data(data.body_part);
    set_year_data(dayjs(new Date(`${data.year}-01-01`)).format("YYYY-MM-DD"));
    set_chassisno_data(data.chassis_no);
    set_cylinder_data(data.cylinder);
    set_region_data(data.region);
    set_cus_name(data.cus_name);
    set_phone_data(data.phone);
    set_file_data(data.image);
    setQueryMode(mode);
    setTimeout(() => {
      if (file_DataRef.current) {
        file_DataRef.current.disabled = true;
      }
      handleOpen();
    }, 0);
  };
  const handleFocus = (event) => {
    event.target.select();
  };
  const handleKeyPress = (event, queryId, field) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      if (field === "best_price_received") {
        handleQueryUpdate("", false, "", false, "", false, value, true, queryId, "", false, false);
      } else if (field === "price_given") {
        handleQueryUpdate("", false, "", false, value, true, "", false, queryId, "", false, false);
      }
    }
  };
  const handleQueryUpdate = (supplier, bSupplier, status, bStatus, price_given, bPrice_given, best_price_received, bBest_price_received, query_id, deliveryCost, bdeliveryCost, mdetails, bmdetails, disable, remarks, bremarks) => {
    // document.getElementById('price_percentage' + query_id).disabled = true
    // document.getElementById('delivery_cost' + query_id).disabled = true
    // document.getElementById('price_given' + query_id).disabled = true
    if (bStatus && status == "Postponed") mdetails.statusValue = null;
    axios.post(API_DOMAIN + "/whatsapp/update_queryset", { supplier, bSupplier, status, bStatus, price_given, bPrice_given, best_price_received, bBest_price_received, query_id, deliveryCost, bdeliveryCost, mdetails, bmdetails, disable, remarks, bremarks }, head).then((res) => {
      setQueryDataMap((prevData) => ({
        ...prevData,
        [query_id]: {
          ...prevData[query_id],
          ...(supplier !== "" && { supplier }),
          ...(status !== "" && { status }),
          ...(price_given !== "" && { price_given }),
          ...(best_price_received !== "" && { best_price_received }),
          ...(deliveryCost !== "" && { deliveryCost }),
          ...(mdetails !== "" && { mdetails }),
        },
      }));
      if (status) {
        handlePriceValueChanges(query_id, "status", status);
        var tempData = querySet;
        for (let i = 0; i < tempData.length; i++) {
          if (tempData[i].query_id == query_id) {
            tempData[i].status = status;
            break;
          }
        }
        setquerySet(tempData);
      }
      // document.getElementById('price_percentage' + query_id).disabled = false
      // document.getElementById('delivery_cost' + query_id).disabled = false
      // document.getElementById('price_given' + query_id).disabled = false


      SuccessSnackbar("Updated Queryset successfully!");
      //removing whatsapp edit mode
      setEditWhatsapp(false);

      //after contemplation i found this is not necessary as the current data is already updated both in front and backend
      //only seems to make the UI slower
      // reloadQuerySet();
    })
      .catch((error) => {
        // document.getElementById('price_percentage' + query_id).disabled = false
        // document.getElementById('delivery_cost' + query_id).disabled = false
        // document.getElementById('price_given' + query_id).disabled = false
        // Handle error if needed
      });
  };
  const handleUnsend = (query_id) => {
    axios
      .post(
        API_DOMAIN + "/whatsapp/delete_messages",
        { api_secret: "uzdpart", query_id },
        head
      )
      .then((res) => {
        if (res.data.success) {
          SuccessSnackbar("Unsent Queryset successfully!");
        } else {
          ErrorSnackbar("Unsent Queryset Failed!\nPlease try again later!");
        }
        reloadQuerySet();
      })
      .catch((error) => {
        // Handle error if needed
      });
  };
  const handleParallelChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    setParallel(checked);
  };
  const handleMakeChange = (value) => {
    if (value.length > 0) {
      axios.post(API_DOMAIN + "/whatsapp/get_suppliers", { make: value, api_secret: "uzdpart" }, head)
        .then((res) => {
          if (res.data.success) {
            setSuppCount(res.data.data.length);
          }
        });
    } else {
      setSuppCount(0);
    }
  };
  const handleBrandChange = async (value) => {
    set_model_data(null);
    set_key_to_reset(dayjs().format("YYYY-MM-DD HH:mm:ss"));
    set_brand_data(value);
    if (
      value != "" &&
      value != null &&
      brandSet.some((element) => element["label"] == value?.label)
    ) {
      load_models(value.label);
    } else {
      setModelSet([]);
    }
  };
  const handle_model_change = async (value) => {
    set_model_data(value);
    axios.post(API_DOMAIN + "/whatsapp/get_last_month_prices", { make: brand_data, model: value }, head)
      .then((res) => {
        if (res.data.success) {
          setPriceHistory(res.data.data);
        }
      });
  };
  const handleCusNameChange = (cusId) => {
    set_cus_name(cusId);
    var mobile = "";
    for (let i = 0; i < cus_name_set.length; i++) {
      if (cus_name_set[i].customer_id == cusId) {
        mobile = cus_name_set[i].customer_mobile;
        break;
      }
    }
    set_phone_data(mobile);
  };
  const handleCusMobChange = (phone) => {
    if (phone) {
      for (let i = 0; i < cus_name_set.length; i++) {
        if (cus_name_set[i].customer_mobile == phone) {
          set_cus_name(cus_name_set[i].customer_id);
          break;
        }
      }
    } else {
      set_cus_name("");
    }
  };
  const handleExcelClick = () => {
    generateExcel(querySet);
  };
  const handlePdfClick = () => {
    generatePDF(querySet);
  };
  const resetInput = (id) => {
    switch (id) {
      case "search_field":
        setSearchTerm("");
        break;
    }
  };
  const handleModalAdd = (e, title) => {
    if (!modal_data) {
      WarningSnackbar("Please Enter a Value first");
      return;
    }
    switch (title) {
      case "Add New Region":
        axios.post(API_DOMAIN + "/whatsapp/region_add_update", { region: modal_data }, head).then((res) => {
          if (res.data.success) {
            setRegionDataSet(res.data.data);
            set_region_data(modal_data);
            SuccessSnackbar("Region added successfully");
            handleCloseAddModal();
          }
        });
        break;
      case "Add New Make":
        axios.post(API_DOMAIN + "/whatsapp/add_makes_v2", { name: modal_data }, head).then((res) => {
          if (res.data.success) {
            const makeSetTemp = res.data.data.map((item) => ({
              id: item.id,
              label: item.name,
            }));
            setbrandSet(makeSetTemp);
            set_brand_data(modal_data);
            SuccessSnackbar("Make added successfully");
            handleCloseAddModal();
          } else {
            ErrorSnackbar(res.data.message);
          }
        });
        break;
      case "Add New '" + brand_data.label + "' Model":
        axios.post(API_DOMAIN + "/whatsapp/add_model_v2", { make: brand_data, model: modal_data }, head).then((res) => {
          if (res.data.success) {
            const makeSetTemp = res.data.data.map((item) => ({
              id: item.id,
              label: item.name,
            }));
            setModelSet(makeSetTemp);
            set_model_data(modal_data);
            SuccessSnackbar("Model added successfully");
            handleCloseAddModal();
          } else {
            ErrorSnackbar(res.data.message);
          }
        });
        break;
    }
  };
  const handleRegionChange = (value) => {
    set_region_data(value);
  };
  const onSearchDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const onAdd = useCallback(
    (newTag) => {
      set_make_data([...make_data, newTag]);
    },
    [make_data]
  );
  const onDelete = useCallback(
    (tagIndex) => {
      set_make_data(make_data.filter((_, i) => i !== tagIndex));
    },
    [make_data]
  );

  //miscellaneous functions
  const showNotification = (main, body, logo) => {
    if (Notification.permission === "granted") {
      new Notification(main ? main : "Hello!", {
        body: body ? body : "This is a system-level notification.",
        icon: logo
          ? logo
          : "https://www.uzdpart.com/wp-content/themes/uzdpart-child/assets/images/logo.PNG",
      });
    } else {
      console.log("Notification permission not granted.");
    }
  };
  const generatePDF = (data) => {
    console.log("data =", data);
    if (data.length > 0) {
      SuccessSnackbar(
        `Preparing PDF from ${dayjs(startDate).format("DD-MM-YYYY")} to ${dayjs(
          endDate
        ).format("DD-MM-YYYY")} ${searchStatus != "" ? "with status " + searchStatus : "."
        } contains ${data.length} rows.`
      );
      const docDefinition = {
        pageSize: "A3",
        pageOrientation: "landscape",
        content: [
          {
            text: `Results between (${dayjs(startDate).format(
              "YYYY-MM-DD"
            )} ~ ${dayjs(endDate).format("YYYY-MM-DD")}), Status : ${searchStatus ? searchStatus : "<All Statuses>"
              }, Total Rows : ${data.length}`,
            margin: [0, 20, 0, 8],
          },
          {
            style: "tableExample",
            table: {
              body: [
                [
                  { text: "SlNo", style: "tableHeader" },
                  { text: "Query ID", style: "tableHeader" },
                  { text: "Date", style: "tableHeader" },
                  { text: "Make", style: "tableHeader" },
                  { text: "Model", style: "tableHeader" },
                  { text: "Year", style: "tableHeader" },
                  { text: "Part", style: "tableHeader" },
                  { text: "Customer Name", style: "tableHeader" },
                  { text: "Phone", style: "tableHeader" },
                  { text: "Supplier", style: "tableHeader" },
                  { text: "Best Price Received", style: "tableHeader" },
                  { text: "Price Given", style: "tableHeader" },
                  { text: "Status", style: "tableHeader" },
                  { text: "Remarks", style: "tableHeader" },
                ],
                ...data.map((item, key) => [
                  key + 1,
                  item.query_id,
                  item.date,
                  item.make,
                  item.model,
                  item.year,
                  item.body_part,
                  item.cus_name || "N/A",
                  item.phone || "N/A",
                  item.supplier != null &&
                    item.supplier != "" &&
                    item.supplier != undefined
                    ? supplierIdToName[item.supplier] ?? "N/A"
                    : "N/A",
                  item.best_price_received,
                  item.price_given,
                  item.status,
                  "",
                ]),
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 5],
                },
                tableExample: {
                  margin: [0, 5, 0, 15],
                },
                tableHeader: {
                  bold: true,
                  fontSize: 13,
                  color: "black",
                },
              },
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 && rowIndex != 0
                  ? "#CCCCCC"
                  : rowIndex == 0
                    ? "#2AFD00"
                    : null;
              },
            },
          },
        ],
      };

      pdfMake.createPdf(docDefinition).download(`queries_${dayjs(startDate).format("YYYY-MM-DD")}_${dayjs(endDate).format("YYYY-MM-DD")}${searchStatus ? "_" + searchStatus.replaceAll(" ", "_") : ""}.pdf`);
      //not needed as almost all modern browsers open PDFs directly automatically after downloading
      // pdfMake.createPdf(docDefinition).open();
    } else {
      alert(
        "Refine your search conditions to have some results in-order to generate PDF."
      );
    }
    return;
  };
  const generateExcel = (data) => {
    if (data.length > 0) {
      SuccessSnackbar(
        `Exporting data to Excel format, from ${dayjs(startDate).format(
          "DD-MM-YYYY"
        )} to ${dayjs(endDate).format("DD-MM-YYYY")}${searchStatus != "" ? ", with status " + searchStatus : "."
        } contains ${data.length} rows.`
      );
      // Convert data to worksheet format
      const worksheetData = [
        [
          "SlNo",
          "Query ID",
          "Date",
          "Make",
          "Model",
          "Year",
          "Part",
          "Customer Name",
          "Phone",
          "Supplier",
          "Best Price Received",
          "Price Given",
          "Status",
          "Remarks",
        ],
        ...data.map((item, key) => [
          key + 1,
          item.query_id,
          item?.timeline[0]
            ? (() => {
              const [key, val] = Object.entries(item.timeline[0])[0];
              return val;
            })()
            : item.date,
          item.make,
          item.model,
          item.year,
          item.body_part,
          item.cus_name || "N/A",
          item.phone || "N/A",
          item.supplier != ""
            ? supplierIdToName[item.supplier] ?? "N/A"
            : "N/A",
          item.best_price_received,
          item.price_given,
          item.status,
          "",
        ]),
      ];

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Queries");

      // Format the filename
      const filename = `queries_${dayjs(startDate).format(
        "YYYY-MM-DD"
      )}_${dayjs(endDate).format("YYYY-MM-DD")}${searchStatus ? "_" + searchStatus.replaceAll(" ", "_") : ""
        }.xlsx`;

      // Write workbook and download
      XLSX.writeFile(workbook, filename);
    } else {
      alert(
        "Refine your search conditions to have some results in-order to export to Excel."
      );
    }
  };

  function phonenumberformat(number) {
    number = number.replace(/[^0-9]/g, "");
    if (number.length === 10) {
      if (number.startsWith("05")) {
        number = number.replace("05", "9715");
      } else {
        number = "91" + number;
      }
    }
    number = number + "@c.us";
    return number;
  };

  const reload_cost_prices = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_costprices", head).then((res) => {
      if (res.data.success) {
        setCostPriceSet(res.data.data);
      }
    });
  };
  const reload_customers = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_customers", head).then((res) => {
      if (res.data.success) {
        set_cus_name_set(res.data.data);
      }
    });
  };
  const reload_suppliers = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_suppliersV2", head).then((res) => {
      setsupplierSet(res.data.data);
      // console.log("res.data.data = " + JSON.stringify(res.data.data));
      var supSet = supplierSet;
      phoneToNameMap = supSet.reduce((map, supplier) => {
        map[phonenumberformat(supplier.number)] = supplier.name;
        return map;
      }, {});
      supplierIdToName = supSet.reduce((map, supplier) => {
        map[supplier.id] = supplier.name;
        return map;
      }, {});
    });
  };
  const reload_makes = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_supplier_tags", head).then((res) => {
      if (res.data.success) {
        var makeSetTemp = [];
        // const transformedQuerySet = makeSetTemp.map(item => item.name);
        for (let i = 0; i < res.data.data.length; i++) {
          makeSetTemp.push({
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          });
        }
        setmakeSet(makeSetTemp);
      }
    });
  };
  const reload_querysets = () => {
    var from = dayjs(startDate).format("YYYY-MM-DD");
    var to = dayjs(endDate).format("YYYY-MM-DD");
    setLoading(true);
    axios.post(API_DOMAIN + "/whatsapp/get_querysets", { from, to, id: authState.id }, head).then((res) => {
      setLoading(false);
      if (res.data.success) {
        handling_setquerySet(res.data.data);
      }
    })
      .catch((error) => {
        console.error("Error reloading querysets:", error);
        setLoading(false);
      });

    // setTimeout(() => {
    //     reload_querysets(head);
    // }, 5000); //repeat call after 3 minutes (180000 milliseconds)
  };
  const reload_region = () => {
    axios.post(API_DOMAIN + "/whatsapp/get_region", head).then((res) => {
      setRegionDataSet(res.data.data);
    });
  };
  const reload_brands = async (value = "") => {
    try {
      set_model_data(null);
      const res = await axios.post(API_DOMAIN + "/whatsapp/get_makes_v2", {
        name: value,
      }, head);
      if (res.data.success) {
        const makeSetTemp = res.data.data.map((item) => ({
          id: item.id,
          label: item.name,
        }));
        setbrandSet(makeSetTemp);
      }
    } catch (error) {
      console.error("Error reloading brands:", error);
    }
  };
  const load_models = (value) => {
    axios.post(API_DOMAIN + "/whatsapp/get_models_v2", { make: value }, head)
      .then((res) => {
        if (res.data.success) {
          const ModelTemp = res.data.data.map((item) => ({
            id: item.id,
            label: item.model,
          }));
          setModelSet(ModelTemp);
        }
      });
  };
  const load_users = () => {
    axios.get(API_DOMAIN + "/whatsapp/get_users", head).then((res) => {
      if (res.data.success) {
        setUsersSet(res.data.data);
      }
    });
  };

  const computePriceToPercent = (bestPrice, givenPrice, DeliveryCost) => {
    return (
      ((parseFloat(givenPrice) -
        parseFloat(bestPrice) -
        parseFloat(DeliveryCost)) /
        parseFloat(bestPrice)) *
      100
    ).toFixed(2);
  };

  const computePercentToPrice = (bestPrice, percentage, DeliveryCost) => {
    // alert("bestPrice=" + bestPrice + "percentage= " + percentage + "DeliveryCost=" + DeliveryCost)
    const price = (
      (parseFloat(bestPrice) * parseFloat(percentage)) / 100 +
      parseFloat(bestPrice) +
      parseFloat(DeliveryCost)
    ).toFixed(2);
    return price;
  };

  const handlePriceValueChanges = (query_id, field, value) => {
    setPriceValues((prevPriceValues) => {
      const updatedPriceValues = {
        ...prevPriceValues,
        [query_id]: {
          ...prevPriceValues[query_id],
          [field]: value,
        },
      };
      return updatedPriceValues;
    });
  };

  //best price received change handler
  const handleBestPriceChange = (query_id, best_price_received) => {
    handlePriceValueChanges(query_id, "best_price_received", best_price_received);

    if (best_price_received) {
      var cost_data = (best_price_received != 0
        ? costPriceSet.find((item) => best_price_received >= item.MinPrice && best_price_received <= item.MaxPrice)
        : 0) || { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 };

      handlePriceValueChanges(query_id, "price_percentage", cost_data.GP_Percentage);
      handlePriceValueChanges(query_id, "delivery_cost", cost_data);

      handlePriceValueChanges(query_id, "price_given", 0);

      const price_percentage = cost_data.GP_Percentage;

      if (price_percentage != 0) {
        var price = computePercentToPrice(best_price_received, price_percentage, cost_data.DeliveryCost);
        handlePriceValueChanges(query_id, "price_given", price);
      }
    } else {
      handlePriceValueChanges(query_id, "price_percentage", 0);
      handlePriceValueChanges(query_id, "price_given", 0);
      handlePriceValueChanges(query_id, "delivery_cost", {
        DeliveryCost: 0,
        GP_Percentage: 0,
        MinPrice: 0,
        MaxPrice: 0,
      });
    }
  };

  //percentage number change handler
  const handlePercentageChange = (query_id, percent) => {
    const best_price = priceValues[query_id]["best_price_received"] ?? 0;
    if (best_price == 0) {
      alert("Please enter the Best Price received for the item first.");
    } else {
      handlePriceValueChanges(query_id, "price_percentage", percent);

      const deliveryCost = priceValues[query_id]["delivery_cost"]["DeliveryCost"];

      if (percent != 0) {
        var price = computePercentToPrice(best_price, percent, deliveryCost);
        handlePriceValueChanges(query_id, "price_given", price);
      } else {
        handlePriceValueChanges(query_id, "price_given", best_price + deliveryCost);
      }
    }
  };

  //given price change handler
  const handleGivenPriceChange = (query_id, given_price) => {
    const best_price = priceValues[query_id]["best_price_received"] ?? 0;

    if (best_price == 0) {
      alert("Please enter the Best Price received for the item first.");
    } else if (given_price == 0) {
      handlePriceValueChanges(query_id, "price_given", given_price);
      handlePriceValueChanges(query_id, "price_percentage", 0);
      handlePriceValueChanges(query_id, "delivery_cost", {
        DeliveryCost: 0,
        GP_Percentage: 0,
        MinPrice: 0,
        MaxPrice: 0,
      });
    } else {
      handlePriceValueChanges(query_id, "price_given", given_price);
      const deliveryCost =
        priceValues[query_id]["delivery_cost"]["DeliveryCost"];

      const percentage = computePriceToPercent(best_price, given_price, deliveryCost);
      handlePriceValueChanges(query_id, "price_percentage", percentage);
    }
  };

  //given price change handler
  const handleDeliverycostChange = (query_id, delivery_cost) => {
    const best_price = priceValues[query_id]["best_price_received"] ?? 0;

    if (best_price == 0) {
      alert("Please enter the Best Price received for the item first.");
    } else {
      if (delivery_cost) {
        handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: delivery_cost, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0, });

        const percentage = priceValues[query_id]["price_percentage"];

        const given_price = computePercentToPrice(best_price, percentage, delivery_cost);
        handlePriceValueChanges(query_id, "price_given", given_price);
      } else {
        handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: delivery_cost, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0, });

        const percentage = priceValues[query_id]["price_percentage"];

        const given_price = computePercentToPrice(best_price, percentage, 0);
        handlePriceValueChanges(query_id, "price_given", given_price);
      }
    }
  };

  //update to DB upon any price change
  const handlePriceUpdate = (query_id) => {
    const status = priceValues[query_id]["status"];

    const best_price_received = priceValues[query_id]["best_price_received"];
    const price_given = priceValues[query_id]["price_given"];
    const deliveryCost = priceValues[query_id]["delivery_cost"]["DeliveryCost"];
    if (best_price_received != 0 && price_given != 0) {
      if (status == "Waiting for Price") {
        handleQueryUpdate("", false, "Waiting for confirmation", true, price_given, true, best_price_received, true, query_id, deliveryCost, true, "", false, false);
        handlePriceValueChanges(query_id, "status", "Waiting for confirmation");
        SuccessSnackbar("Query status also has been updated for your convenience!");
      } else {
        handleQueryUpdate("", false, "", false, price_given, true, best_price_received, true, query_id, deliveryCost, true, "", false, false);
      }
    }
  };

  //update to DB upon any remarks change
  const handleRemarksUpdate = (query_id) => {

    const remark = priceValues[query_id]["remarks"];

    if (remark) {
      handleQueryUpdate("", false, "", false, "", false, "", false, query_id, "", false, "", false, false, remark, true);
      SuccessSnackbar("Remarks updated successfully!");
    }
  };

  // socket.on('QueryDataUpdated', (values) => {
  //     console.log("QueryDataUpdated = " + JSON.stringify(values));
  //     // var data = values.data;
  //     // SuccessSnackbar(values.message, '(ID : ' + data[0].query_id + ")");
  //     // handling_updated_query(data);
  //     SuccessSnackbar("New data available", "Load new data", () => handling_updated_query(values.data), 30000);
  // })
  // socket.on('newQueryLoaded', (values) => {
  //     // var data = values.data;
  //     // SuccessSnackbar(values.message);
  //     // handling_added_query(data);
  //     SuccessSnackbar("New data available", "Load new data",() =>  handling_added_query(values.data), 30000);
  // })
  const getStatusColor = (status) => {
    const statusObj = queryStatusSet.find((item) => item.name === status);
    return statusObj ? statusObj.colour || "gray" : "gray";
  };

  const handleRemarksChange = (query_id, value) => {
    setPriceValues((prevData) => ({
      ...prevData,
      [query_id]: {
        ...prevData[query_id],
        remarks: value,
      },
    }));
  }

  useEffect(() => {
    const head = {
      headers: {
        accessToken: localStorage.getItem("accessToken")
      }
    };
    load_users();
    reload_querysets(head);
    //this is the tags
    reload_makes(head);
    //brand names, models will load when a brand is selected
    reload_brands(head);
    //now it loads the supplierv2 data
    reload_suppliers(head);
    reload_customers(head);
    reload_region(head);
    reload_cost_prices(head);
    const waStatusCheck = async (head) => {
      axios.post(API_DOMAIN + "/whatsapp/status", { api_secret: "uzdpart" }, head).then((res) => {
        if (res.data.success) {
          setWhatsappStatus(res.data.data.status);
          switch (res.data.data.status.status) {
            case eventStatusMap.logged_in:
              document.getElementById("wa_status_img_small").src = green_tick;
              break;
            default:
              document.getElementById("wa_status_img_small").src =
                yellow_error;
              setTimeout(() => {
                waStatusCheck();
              }, 3000);
              break;
          }
        } else {
          document.getElementById("wa_status_img_small").src = yellow_error;
        }
      })
        .catch((err) => {
          ErrorSnackbar(err.message);
        });
    };
    axios.post(API_DOMAIN + "/whatsapp/get_query_succ_status", { api_secret: "uzdpart" }, head)
      .then((res) => {
        if (res.data.success) {
          console.log("res.data.success = " + JSON.stringify(res.data.data));

          setQueryStatusSet(res.data.data);
        }
      })
      .catch((err) => {
        ErrorSnackbar(err.message);
      });
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          alert(
            "Please allow to show notifications so that You'll know when the query got resolved."
          );
          console.log("Notification permission denied.");
        }
      });
    }
    waStatusCheck();

    const handleWindowFocus = () => {
      setIsTabFocused(true);
    };

    const handleWindowBlur = () => {
      setIsTabFocused(false);
      queryDateRef?.current?.setOpen(false);
    };

    window.addEventListener("focus", handleWindowFocus);
    window.addEventListener("blur", handleWindowBlur);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("blur", handleWindowBlur);
      socket.off("QueryDataUpdated");
      socket.off("newQueryLoaded");
    };
  }, []);
  useEffect(() => {
    isTabFocusedRef.current = isTabFocused;
  }, [isTabFocused]);
  useEffect(() => {
    handleMakeChange(make_data);
  }, [make_data]);

  const [priceValues, setPriceValues] = useState({});
  const [parallel, setParallel] = useState(false);
  const [schedule_wati, setschedule_wati] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [queryDataMap, setQueryDataMap] = useState({});

  const reloadQuerySet = () => {
    setIsDownloadButtonDisabled(true);
    setLoading(true);

    // Check if the user should be prompted to confirm
    if (!startDate && !endDate) {
      const confirmFetch = window.confirm("No dates selected, Search will scan the whole database, may take longer this way.");
      // If the user declines, skip the data fetch and reset the states
      if (!confirmFetch) {
        setIsDownloadButtonDisabled(false);
        setLoading(false);
        return;
      }
    }

    axios.post(API_DOMAIN + "/whatsapp/get_querysets", { search: searchTerm, from: dayjs(startDate).format("YYYY-MM-DD"), to: dayjs(endDate).format("YYYY-MM-DD"), status: searchStatus, api_secret: "uzdpart", id: authState.id, user: searchUser }, head)
      .then((res) => {
        setIsDownloadButtonDisabled(false);
        if (res.data.success) {
          setPageNumber(0);
          setLoading(false);
          handling_setquerySet(res.data.data);

          if (
            searchTerm ||
            dayjs(startDate).isValid() ||
            dayjs(endDate).isValid() ||
            searchStatus
          ) {
            SuccessSnackbar("Fetched " + res.data.data.length + " search results");
          }
        }
      })
      .catch((error) => {
        ErrorSnackbar("Error fetching data, Please try again later. " + error.message);
        setLoading(false);
      });
  };

  const handleScheduleChange = (e) => {
    setschedule_wati(e.target.checked);
  };

  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: "This Month",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: "Next Month",
      getValue: () => {
        const today = dayjs();
        const startOfNextMonth = today.endOf("month").add(1, "day");
        return [startOfNextMonth, startOfNextMonth.endOf("month")];
      },
    },
    {
      label: "Today",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("day"), today.endOf("day")];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];
  // Pagination Information
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 50;
  const visitedPages = pageNumber * usersPerPage;
  const pageCount = Math.ceil(querySet.length / usersPerPage);
  const changePage = ({ selected }) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPageNumber(selected);
  };
  const columns = [
    {
      name: "query_id",
      label: "Query ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "timeline",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value && value.length > 0 && value[0].query_created
            ? value[0].query_created
            : "Timeline data not available";
        },
      },
    },
    {
      name: "user",
      label: "User",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "make",
      label: "Make - Model",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          // Get the whole row's data using dataIndex
          const rowData = querySet[dataIndex];

          // Combine the two column values
          return `${rowData.make} - ${rowData.model} ${rowData.model_details ? `(${rowData.model_details})` : ""}`;;
        },
      },
    },
    {
      name: "body_part",
      label: "Part",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "chassis_no",
      label: "VIN",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "region",
      label: "Region",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cus_name",
      label: "Customer",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "price_given",
      label: "SP",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const renderExpandableRow = (rowData, rowMeta, querySet) => {
    const data = queryDataMap[rowData[0]];

    const settings = {
      dots: true,
      infinite: data.image.length > 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: data.image.length > 1,
      autoplaySpeed: 3000,
    };

    return (
      <TableRow>
        <TableCell colSpan={13}>
          <Divider
            orientation="horizontal"
            style={{
              backgroundColor: "black",
              height: "2px",
              width: "100%",
              borderRadius: "2px",
            }}
            variant="middle"
            flexItem
          />
          <div style={{ display: "flex" }}>
            <div
              className="image-slider-container"
              style={{ margin: "20px 30px 20px 30px", width: "220px" }}
            >
              <Slider {...settings}>
                {data.image.map((item, index) => (
                  <div key={index} style={{ width: "220px", height: "220px" }}>
                    <img
                      style={{
                        width: "220px",
                        border: "2px solid",
                        borderRadius: "10px",
                        height: "220px",
                      }}
                      src={API_DOMAIN + "/" + item}
                      alt={`${API_DOMAIN}/images/no_img.jpg`}
                      loading="lazy"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <Divider
              style={{
                backgroundColor: "black",
                width: "2px",
                margin: "5px",
                borderRadius: "2px",
              }}
              orientation="vertical"
              variant="middle"
              flexItem
            />

            {/* <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
              </div>
            </nav> */}




            {/* <div
              style={{
                height: "220px",
                overflowY: "auto",
                margin: "25px",
                width: "350px",
              }}
            >
              <TableContainer
                component={Paper}
                style={{ border: "1px gray solid" }}
              >
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "600" }}>
                        Status
                      </TableCell>
                      <TableCell style={{ fontWeight: "600" }} align="right">
                        Date & Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.timeline ? (
                      data.timeline.map((element, index) =>
                        Object.entries(element).map(([key, value]) => (
                          <TableRow
                            key={key + index} // Ensure a unique key for each cell
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {key}
                            </TableCell>
                            <TableCell align="right">{value}</TableCell>
                          </TableRow>
                        ))
                      )
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" colSpan={2}>
                          No timeline data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            
            
            <div
              style={{
                height: "220px",
                overflowY: "auto",
                margin: "25px",
                width: "350px",
              }}
            >
              {data?.timeline ? (
                <Typography>
                  Total Time Spent on Query :{" "}
                  {`${String(
                    Math.floor(
                      moment
                        .duration(
                          moment(
                            Object.values(
                              data.timeline[data.timeline.length - 1]
                            )[0],
                            "YYYY-MM-DD HH:mm:ss A"
                          ).diff(
                            moment(
                              Object.values(data.timeline[0])[0],
                              "YYYY-MM-DD HH:mm:ss A"
                            )
                          )
                        )
                        .asHours()
                    )
                  ).padStart(2, "0")}:${String(
                    moment
                      .duration(
                        moment(
                          Object.values(
                            data.timeline[data.timeline.length - 1]
                          )[0],
                          "YYYY-MM-DD HH:mm:ss A"
                        ).diff(
                          moment(
                            Object.values(data.timeline[0])[0],
                            "YYYY-MM-DD HH:mm:ss A"
                          )
                        )
                      )
                      .minutes()
                  ).padStart(2, "0")}:${String(
                    moment
                      .duration(
                        moment(
                          Object.values(
                            data.timeline[data.timeline.length - 1]
                          )[0],
                          "YYYY-MM-DD HH:mm:ss A"
                        ).diff(
                          moment(
                            Object.values(data.timeline[0])[0],
                            "YYYY-MM-DD HH:mm:ss A"
                          )
                        )
                      )
                      .seconds()
                  ).padStart(2, "0")}`}
                </Typography>
              ) : (
                <div>No timeline data available</div>
              )}
              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: "black",
                  height: "2px",
                  margin: "10px 0px 10px 0px",
                }}
                variant="middle"
                flexItem
              />
              <div>
                <h4>WhatsApp Broadcast Info</h4>
                <table>
                  <tr>
                    <td>
                      <Typography>Total Success</Typography>
                    </td>
                    <td>
                      <Typography>:</Typography>
                    </td>
                    <td style={{ color: "green", justifyContent: "end" }}>
                      <Typography>
                        {data?.moredetails?.success?.length || 0}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Total Failed</Typography>
                    </td>
                    <td>
                      <Typography>:</Typography>
                    </td>
                    <td style={{ color: "red", justifyContent: "end" }}>
                      <Typography>
                        {data?.moredetails?.failed?.length || 0}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <Typography>
                        ---------------------------------------------------------
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Total Sum</Typography>
                    </td>
                    <td>
                      <Typography>:</Typography>
                    </td>
                    <td>
                      <Typography>
                        {data?.moredetails?.failed?.length +
                          data?.moredetails?.success?.length || 0}
                      </Typography>
                    </td>
                  </tr>
                </table>
                <Divider
                  orientation="horizontal"
                  style={{
                    backgroundColor: "black",
                    height: "2px",
                    margin: "3px 0px 3px 0px",
                  }}
                  variant="middle"
                  flexItem
                />
                {data.sup_set.map((item) => item.label).join(", ").length <
                  47 ? (
                  <Typography>
                    {`[${data.sup_set.map((item) => item.label).join(", ")}]`}
                  </Typography>
                ) : (
                  <marquee
                    behavior="alternate"
                    direction="left"
                    scrolldelay="200"
                  >
                    {`[${data.sup_set.map((item) => item.label).join(", ")}]`}
                  </marquee>
                )}
              </div>
            </div> */}


            {/* <Divider
              style={{
                backgroundColor: "black",
                width: "2px",
                margin: "5px",
                borderRadius: "2px",
              }}
              orientation="vertical"
              variant="middle"
              flexItem
            /> */}



            <div style={{ height: "240px", overflowY: "auto", margin: "25px" }}>
              <table>
                <tr>
                  <td>Supplier</td>
                  <td>:</td>
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <select
                      class="custom-select label-look"
                      style={{ width: "375px" }}
                      value={data.supplier}
                      onChange={(e) =>
                        handleQueryUpdate(
                          e.target.value,
                          true,
                          "",
                          false,
                          "",
                          false,
                          "",
                          false,
                          data.query_id,
                          "",
                          "",
                          "",
                          false,
                          false
                        )
                      }
                    >
                      <option value=""></option>
                      {supplierSet.map((item) => (
                        <option key={item.id} value={item.id ? item.id : ""}>
                          {item.name}
                        </option>
                      ))}
                    </select>


                    {data.scheduled

                      ?

                      <Tooltip
                        title="Schedule WATI reminder messages"
                        placement="top"
                      >
                        <Button
                          style={{ height: "25px", backgroundColor: "green", marginLeft: "10px" }}
                          variant="contained"
                          endIcon={<MarkEmailReadIcon />}
                          disabled
                        >
                          Already Scheduled
                        </Button>
                      </Tooltip>

                      :
                      <Tooltip
                        title="Schedule WATI reminder messages"
                        placement="top"
                      >
                        <Button
                          style={{ height: "25px", backgroundColor: "blue", marginLeft: "10px" }}
                          variant="contained"
                          endIcon={<ScheduleSendOutlinedIcon />}
                          onClick={() => handleScheduleWatiMessages(data.query_id)}
                        >
                          Schedule WATI MSGs
                        </Button>
                      </Tooltip>}

                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <Divider
                      orientation="horizontal"
                      style={{
                        backgroundColor: "black",
                        height: "2px",
                        marginBottom: "10px",
                        marginTop: "10px",
                        width: "85%",
                        borderRadius: "2px",
                      }}
                      variant="middle"
                      flexItem
                    />
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>
                    <select
                      id="status_value"
                      value={priceValues[data.query_id]["status"]}
                      class="custom-select label-look"
                      onChange={(e) =>
                        handleQueryUpdate(
                          "",
                          false,
                          e.target.value,
                          true,
                          "",
                          false,
                          "",
                          false,
                          data.query_id,
                          "",
                          "",
                          data.moredetails,
                          true,
                          false
                        )
                      }
                      style={{ width: "85%" }}
                    >
                      <option value=""></option>
                      {queryStatusSet.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Remarks</td>
                  <td>:</td>
                  <td>
                    <input
                      type="text"
                      value={priceValues[data.query_id]["remarks"]}
                      onChange={(e) => handleRemarksChange(data.query_id, e.target.value)}
                      onBlur={(e) => handleRemarksUpdate(data.query_id)}
                      style={{
                        width: "85%",
                        borderRadius: "5px",
                        border: "1px black solid",
                      }}
                    ></input>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <Divider
                      orientation="horizontal"
                      style={{
                        backgroundColor: "black",
                        height: "2px",
                        marginBottom: "10px",
                        marginTop: "10px",
                        width: "85%",
                        borderRadius: "2px",
                      }}
                      variant="middle"
                      flexItem
                    />
                  </td>
                </tr>

                <tr>
                  <td>BPrice</td>
                  <td>:</td>
                  <td>
                    <input
                      id="best_price_received"
                      style={{
                        width: "85%",
                        borderRadius: "5px",
                        border: "1px solid black",
                      }}
                      value={priceValues[data.query_id]["best_price_received"]}
                      onFocus={handleFocus}
                      onChange={(e) =>
                        handleBestPriceChange(data.query_id, e.target.value)
                      }
                      onBlur={(e) => handlePriceUpdate(data.query_id)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>SPrice</td>
                  <td>:</td>
                  <td>
                    <div
                      key={data.query_id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {priceValues[data.query_id]["price_percentage"] == 0 ? (
                        <div>
                          <ImportExport />
                        </div>
                      ) : priceValues[data.query_id]["price_percentage"] > 0 ? (
                        <div style={{ color: "green" }}>
                          <ArrowUpwardIcon />
                        </div>
                      ) : (
                        <div style={{ color: "red" }}>
                          <ArrowDownwardIcon />
                        </div>
                      )}
                      <div
                        style={{
                          color: "grey",
                          transform: "translateX(15px)",
                          marginLeft: "-12px",
                        }}
                      >
                        %
                      </div>

                      <input
                        style={{
                          textAlign: "end",
                          width: "15.7%",
                          borderRadius: "5px",
                          border: "1px black solid",
                        }}
                        id={"price_percentage" + data.query_id}
                        value={priceValues[data.query_id]["price_percentage"]}
                        onChange={(e) =>
                          handlePercentageChange(data.query_id, e.target.value)
                        }
                        onBlur={(e) => handlePriceUpdate(data.query_id)}
                      />
                      <div>&nbsp;&nbsp;+&nbsp;</div>
                      <div
                        style={{
                          color: "grey",
                          transform: "translateX(25px)",
                          marginLeft: "-22px",
                        }}
                      >
                        <LocalShippingIcon />
                      </div>
                      <input
                        style={{
                          textAlign: "end",
                          width: "15.7%",
                          borderRadius: "5px",
                          border: "1px black solid",
                        }}
                        id={"delivery_cost" + data.query_id}
                        value={
                          priceValues[data.query_id]["delivery_cost"][
                          "DeliveryCost"
                          ]
                        }
                        onChange={(e) =>
                          handleDeliverycostChange(
                            data.query_id,
                            e.target.value
                          )
                        }
                        onFocus={handleFocus}
                        onBlur={(e) => handlePriceUpdate(data.query_id)}
                        placeholder="Delivery Cost"
                      />
                      <div>&nbsp;&nbsp;=&nbsp;</div>
                      <div
                        style={{
                          color: "grey",
                          transform: "translateX(25px)",
                          marginLeft: "-22px",
                          fontSize: "10px",
                        }}
                      >
                        <SellIcon />
                      </div>
                      <input
                        style={{
                          width: "30%",
                          border: "1px solid black",
                          borderRadius: "5px",
                          textAlign: "end",
                          fontWeight: "700",
                        }}
                        id={"price_given" + data.query_id}
                        value={priceValues[data.query_id]["price_given"]}
                        onChange={(e) =>
                          handleGivenPriceChange(data.query_id, e.target.value)
                        }
                        onFocus={handleFocus}
                        onBlur={(e) => handlePriceUpdate(data.query_id)}
                      />
                      <div>&nbsp;&nbsp;</div>

                      <Tooltip
                        title="Send the price details to WATI straight."
                        placement="top"
                      >
                        <Button
                          style={{ height: "25px", backgroundColor: "green", width: "92px" }}
                          variant="contained"
                          endIcon={<SendOutlinedIcon />}
                          onClick={() => handleWatiMessages(data)}
                        >
                          WATI
                        </Button>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
                <div style={{ height: "7px" }} />
                <tr>
                  <td>Payment</td>
                  <td>:</td>
                  <td style={{ alignItems: "center", display: "flex" }}>
                    {data.moredetails.hasOwnProperty("payment_details") ? (
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <Button
                          style={{ height: "25px" }}
                          variant="contained"
                          startIcon=""
                          onClick={() =>
                            handleOpenLinkGen(data.query_id, data.moredetails)
                          }
                        >
                          Manage Link
                        </Button>
                        <div
                          style={{
                            width: "60%",
                            overflowX: "hidden",
                            marginLeft: "5px",
                          }}
                        >
                          {data.moredetails?.payment_details?.payment_url ||
                            "Some error occured!"}
                        </div>
                        <Button
                          style={{ height: "25px", backgroundColor: "green" }}
                          variant="contained"
                          endIcon={<SendOutlinedIcon />}
                          onClick={() => handleOpenLinkGen(data)}
                        >
                          WATI
                        </Button>
                      </div>
                    ) : (
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <Button
                          style={{ height: "25px" }}
                          variant="contained"
                          onClick={() => handleOpenLinkGen(data)}
                        >
                          GetLink
                        </Button>
                        <div
                          style={{
                            width: "60%",
                            overflowX: "hidden",
                            marginLeft: "5px",
                          }}
                        >
                          {data.moredetails?.payment_details?.payment_url ||
                            "Link Not Yet Created."}
                        </div>
                      </div>
                    )}
                    &nbsp;
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <Divider
            orientation="horizontal"
            style={{
              backgroundColor: "black",
              height: "2px",
              marginBottom: "20px",
              width: "100%",
              borderRadius: "2px",
            }}
            variant="middle"
            flexItem
          />
        </TableCell>
      </TableRow>
    );
  };
  const handleRowsDelete = (rowsToDelete, setSelectedRows) => {
    const queryIds = rowsToDelete.map((row) => row.query_id).join(", ");
    if (window.confirm(`Do you want to delete these Query IDs: ${queryIds}?`)) {
      rowsToDelete.forEach((row) => {
        handleQueryUpdate(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          row.query_id,
          "",
          "",
          "",
          false,
          true
        );
      });

      const newData = querySet.filter((row) => !rowsToDelete.includes(row));
      setquerySet(newData);
      setSelectedRows([]);
    }
  };

  const options = {
    rowId: "query_id",
    rowHove: true,
    fixedHeader: true,
    selectableRows: "multiple",
    responsive: "standard",
    selectableRowsHeader: true,
    expandableRows: true,
    expandableRowsOnClick: true,
    rowsPerPage: 50, // Set the default number of rows per page
    rowsPerPageOptions: [10, 25, 50, 100, 250, 500],
    filterType: "checkbox",
    renderExpandableRow: renderExpandableRow,
    onRowClick: (rowData, rowMeta) => handleExpandClick(rowMeta.dataIndex),
    setRowProps: (row) => {
      return {
        style: {
          backgroundColor: getStatusColor(row[10]), // Apply background color to the entire row
        },
      };
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        data={querySet}
        onRowsDelete={handleRowsDelete}
        setSelectedRows={setSelectedRows}
        onEdit={handleQueryEdit}
        setEditWhatsapp={setEditWhatsapp}
        setQueryModalTitle={setQueryModalTitle}
        toasts={{ SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer }}
        setQuerid={set_queryid}
      />
    ),
  };

  const handleScheduleWatiMessages = (query_id) => {
    if (window.confirm("Are you sure you want to schedule WATI reminder messages for the query ID: " + query_id + "?")) {
      InfoSnackbar("Scheduling WATI reminder messages for the query ID: " + query_id);
      axios.post(API_DOMAIN + "/whatsapp/schedule_wati_reminders", { query_id }, head).then((res) => {
        if (res.data.success) {
          SuccessSnackbar("WATI reminder messages scheduled successfully for the query ID: " + query_id);
          handleQueryValueChange(query_id, "scheduled", true);
        }
      })
        .catch((error) => {
          ErrorSnackbar(error.message);
        });
    }
    else {
      InfoSnackbar("Scheduling WATI reminder messages for the query ID: " + query_id + " cancelled");
    }
  }

  const loadingOptions = loading
    ? {
      textLabels: {
        body: {
          noMatch: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ),
        },
      },
      selectableRows: "none", // Disable selection while loading
    }
    : {
      textLabels: {
        body: {
          noMatch: "Sorry, no matching records found",
        },
      },
    };

  // Merge the base options with loading-specific ones
  const mergedOptions = {
    ...options,
    ...loadingOptions,
  };

  const save_settings = (settings) => {
    // alert(JSON.stringify(settings));
    axios.post(API_DOMAIN + "/whatsapp/add_query_succ_status", { statuses: settings, }, head).then((res) => { setQueryStatusSet(settings); });
  };

  const [templates, setTemplates] = useState([]);
  const [templateTemp, setTemplatesTemp] = useState([]);
  const handleWatiMessages = (data) => {
    axios.post(API_DOMAIN + "/whatsapp/get_wati_templates", { template: false }, head).then((res) => {
      if (res.data.success) {
        setTemplates(res.data.data);
        setTemplatesTemp(res.data.data);
      } else {
        ErrorSnackbar("Something went wrong while fetching the templates");
      }
    }).catch((error) => { ErrorSnackbar(error.message); });
    handleOpenQuickReplies(data);
  };

  return (
    <>
      <Container fluid={true} style={{ width: "93vw" }}>
        <Row
          style={{
            backgroundColor: "#1497d9",
            border: "1px solid #1497d9",
            borderRadius: "5px",
            paddingBottom: "9px",
          }}
        >
          {/* first column*/}
          <Col>
            <div style={{ display: "flex" }}>
              <input
                value={searchTerm}
                id="search_field"
                style={{
                  height: "2.60em",
                  marginTop: "8px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  width: "180px",
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search"
              ></input>

              <span
                style={{
                  cursor: "pointer",
                  fontSize: "17px",
                  color: "gray",
                  marginTop: "17px",
                  marginRight: "-10px",
                  transform: "translateX(-160%)",
                }}
                class="input-reset"
                onClick={() => resetInput("search_field")}
              >
                &#10005;
              </span>

              <div style={{ fontSize: "8px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["SingleInputDateRangeField"]}>
                    <DateRangePicker
                      slotProps={{
                        shortcuts: {
                          items: shortcutsItems,
                        },
                        actionBar: { actions: [] },
                      }}
                      slots={{ field: SingleInputDateRangeField }}
                      name="allowedRange"
                      format="YYYY-MM-DD"
                      onChange={onSearchDateChange}
                      disableFuture={true}
                      className="daterangepicker_search"
                      value={[startDate, endDate]}
                      defaultValue={[startDate, endDate]}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <div style={{ paddingTop: "8px", marginLeft: "4px" }}>
                <Select
                  id="search_status"
                  onChange={(e) => setSearchStatus(e.target.value)}
                  autoWidth
                  label="Choose Status"
                  style={{
                    height: "2.60em",
                    borderRadius: "5px",
                    border: "1px solid black",
                    width: "180px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {queryStatusSet.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {authState.admin ? (<div style={{ paddingTop: "8px", marginLeft: "4px" }}>
                <Select
                  id="search_user"
                  onChange={(e) => setSearchUser(e.target.value)}
                  autoWidth
                  label="Choose User"
                  style={{
                    height: "2.60em",
                    borderRadius: "5px",
                    border: "1px solid black",
                    width: "180px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {usersSet.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>) : ""}


              <div style={{ paddingTop: "10.5px", marginLeft: "5px" }}>
                <Button
                  variant="contained"
                  classes={"qry_btns"}
                  onClick={reloadQuerySet}
                >
                  <SearchIcon />
                </Button>
              </div>
            </div>
          </Col>

          {/* second column*/}
          <Col>
            <div style={{ display: "flex", paddingTop: "10px" }}>
              <Button
                disabled={isDownloadButtonDisabled}
                variant="contained"
                classes={"qry_btns"}
                id="pdf_button"
                onClick={handlePdfClick}
              >
                <PictureAsPdfIcon /> <FileDownloadIcon />
              </Button>
              <div>&nbsp;</div>

              <Button
                disabled={isDownloadButtonDisabled}
                variant="contained"
                classes={"qry_btns"}
                id="excel_button"
                onClick={handleExcelClick}
              >
                <ExcelLogo /> <FileDownloadIcon />
              </Button>
            </div>
          </Col>

          <Col className="d-flex justify-content-end">
            <Button
              variant="contained"
              className={"qry_btns"}
              sx={{ marginTop: "10px" }}
              onClick={handleOpen}
            >
              <AddCommentIcon />
            </Button>
            <div style={{ width: "5px" }}></div>
            <Button
              variant="contained"
              className={"qry_btns"}
              sx={{ marginTop: "10px" }}
              onClick={handleOpenSettingsModal}
            >
              <SettingsIcon />
            </Button>
          </Col>
        </Row>
        <Row>
          <div style={{ fontSize: "14px" }}>
            Total Rows fetched : {querySet.length}
          </div>
        </Row>
      </Container>
      <br />
      <div className="col-12" style={{ width: "100%" }}>
        <MUIDataTable
          title={"QuerySet"}
          data={loading ? [] : querySet}
          columns={columns}
          options={mergedOptions}
          expandableRowsHeader
          className={"table-row"}
        />
      </div>

      {/*Adding query modal*/}
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {queryModalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ display: "flex" }}>
            <Col>
              <Row>
                <Col>
                  <ReactTags
                    selected={make_data}
                    suggestions={makeSet}
                    placeholderText="Add Supplier Groups"
                    onAdd={onAdd}
                    onDelete={onDelete}
                    noOptionsText="No matches found"
                  />
                </Col>
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <Col>
                  <TextField
                    label="Part Number"
                    variant="outlined"
                    value={partNumber}
                    fullWidth
                    InputProps={{
                      spellCheck: true,
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45, // Adjust height as needed
                      },
                    }}
                    onChange={(e) => {
                      setPartNumber(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <Col>
                  <Autocomplete
                    value={brand_data}
                    onChange={(event, value) => {
                      handleBrandChange(value);
                    }}
                    disablePortal
                    options={brandSet}
                    freeSolo={true}
                    className="custom-autocomplete"
                    isOptionEqualToValue={(option, value) =>
                      option.label === value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Make"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <CustomSpan
                                className="input-reset"
                                onClick={(e) =>
                                  handleOpenAddModal("Add New Make")
                                }
                              >
                                &#65291;
                              </CustomSpan>
                            </>
                          ),
                        }}
                        required={true}
                      />
                    )}
                  ></Autocomplete>
                </Col>
                <Col>
                  <Autocomplete
                    value={model_data}
                    onChange={(event, value) => {
                      handle_model_change(value);
                    }}
                    disablePortal
                    freeSolo={true}
                    key={key_to_reset}
                    options={modelSet}
                    className="custom-autocomplete"
                    isOptionEqualToValue={(option, value) =>
                      option.label === value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Model"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <CustomSpan
                                className="input-reset"
                                onClick={(e) =>
                                  handleOpenAddModal("Add New Model")
                                }
                              >
                                &#65291;
                              </CustomSpan>
                            </>
                          ),
                        }}
                        required={true}
                      />
                    )}
                  ></Autocomplete>
                </Col>
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <Col>
                  <TextField
                    label="Model Details"
                    variant="outlined"
                    fullWidth
                    value={model_details_data}
                    onChange={(e) => {
                      set_model_details_data(e.target.value);
                    }}
                    InputProps={{
                      spellCheck: true,
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45, // Adjust height as needed
                      },
                    }}
                  />
                </Col>
                <Col>
                  <TextField
                    label="Cylinders"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={cylinder_data}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 100,
                        step: 1,
                      },
                    }}
                    onChange={(e) => {
                      set_cylinder_data(e.target.value);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45, // Adjust height as needed
                      },
                    }}
                  />
                </Col>
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <Col>
                  <TextField
                    label="Parts"
                    variant="outlined"
                    multiline
                    value={bodypart_data}
                    rows={4} // Default number of rows; adjusts the height
                    fullWidth
                    required
                    onChange={(e) => {
                      set_bodypart_data(e.target.value);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 98, // Adjust height as needed
                        // Alternatively, adjust the height directly
                        "& textarea": { height: "98px" },
                      },
                    }}
                  />
                </Col>
                <Col>
                  <Select
                    value={region_data}
                    onChange={(event) => {
                      handleRegionChange(event.target.value);
                    }}
                    label="Region"
                    sx={{
                      width: "100%",
                      height: "48px",
                      "& .MuiSelect-icon": {
                        display: "none", // Hide the default dropdown arrow
                      },
                      "& .MuiInputAdornment-root": {
                        right: 0, // Position your custom icon correctly
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          style={{ color: "black" }}
                          onClick={() => handleOpenAddModal("Add New Region")}
                        >
                          &#65291;
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="">
                      <em>Select Region</em>
                    </MenuItem>
                    {regionDataSet.map((item) => (
                      <MenuItem key={item.id} value={item.region}>
                        {item.region}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <div style={{ height: "7px" }}></div>\ */}
                  <div class="add_make_year">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          className="add_make_year"
                          maxDate={dayjs(new Date())}
                          views={["year"]}
                          value={dayjs(year_data)}
                          onChange={(date) => {
                            set_year_data(date);
                          }}
                          minDate={dayjs("2005-01-01")}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Col>
              </Row>
              <div style={{ height: "2px" }}></div>
              <Row>
                <Col>
                  <TextField
                    label="Chassis No (VIN)"
                    variant="outlined"
                    fullWidth
                    required
                    value={chassisno_data}
                    onChange={(e) => {
                      set_chassisno_data(e.target.value);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45, // Adjust height as needed
                      },
                    }}
                  />
                </Col>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Select Images
                      <VisuallyHiddenInput
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Button>
                    <Tooltip title="Activate this icon to send queries parallely, making it faster but server-resource-costly.">
                      <Checkbox
                        icon={<SpeedOutlinedIcon />}
                        checkedIcon={<SpeedIcon />}
                        checked={isChecked}
                        onChange={(e) => handleParallelChange(e)}
                      />
                    </Tooltip>
                    <Tooltip title="Activate this icon to schedule the automated follow-up messages. You can schedule it or remove the scheduled message later. It is on by default.">
                      <Checkbox
                        icon={<ScheduleSendOutlinedIcon />}
                        checkedIcon={<ScheduleSendIcon />}
                        checked={schedule_wati}
                        onChange={(e) => handleScheduleChange(e)}
                      />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <Col>
                  <TextField
                    label="Customer Phone"
                    variant="outlined"
                    fullWidth
                    value={phone_data}
                    onChange={(e) => {
                      handleCusMobChange(e.target.value);
                      set_phone_data(e.target.value);
                    }}
                    required
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45, // Adjust height as needed
                      },
                    }}
                  />
                </Col>
                <Col>
                  <Select
                    value={cus_name}
                    onChange={(event) => {
                      handleCusNameChange(event.target.value);
                    }}
                    label="Customer Name"
                    style={{ width: "100%", height: "45px" }}
                  >
                    <MenuItem value="">
                      <em>Select Customer Name</em>
                    </MenuItem>
                    {cus_name_set.map((item) => (
                      <MenuItem key={item.customer_id} value={item.customer_id}>
                        {item.customer_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
            <div style={{ width: "3px" }}></div>
            <Divider
              style={{
                backgroundColor: "black",
                width: "2px",
                margin: "5px",
                borderRadius: "2px",
              }}
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <Col>
              <Row>
                <div style={{ display: "grid", justifyContent: "center" }}>
                  <Typography>
                    SIMILAR QUERY HISTORY (Based on Make & Model, 30Days)
                  </Typography>
                  <Divider
                    orientation="horizontal"
                    style={{
                      backgroundColor: "black",
                      height: "2px",
                      margin: "5px",
                      borderRadius: "2px",
                    }}
                    variant="middle"
                    flexItem
                  />
                </div>
              </Row>

              <Row>
                <div
                  style={{
                    marginLeft: "6px",
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "auto",
                    maxHeight: "370px",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ Width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>QueryID</TableCell>
                          <TableCell align="right">Part</TableCell>
                          <TableCell align="right">Supplier</TableCell>
                          <TableCell align="right">BP</TableCell>
                          <TableCell align="right">SP</TableCell>
                          <TableCell align="right">Margin</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {priceHistory.map((row) => (
                          <TableRow
                            key={row.query_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.query_id + "\n" + row.date}
                            </TableCell>
                            <TableCell align="right">
                              {row.body_part + "\n" + row.model_details}
                            </TableCell>
                            <TableCell align="right">
                              {row.Supplier + "\n" + row.SupNo}
                            </TableCell>
                            <TableCell align="right">
                              {row.best_price_received}
                            </TableCell>
                            <TableCell align="right">
                              {row.price_given}
                            </TableCell>
                            <TableCell align="right">{row.margin}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Row>
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              endIcon={<CancelOutlinedIcon />}
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
          <div id="">
            <Typography>
              Suppliers with possible availability : {suppCount}
            </Typography>
          </div>
          <div>
            <Button
              endIcon={<SendOutlinedIcon />}
              id="send_query_btn"
              disabled={isQsDisabled}
              variant="contained"
              style={{ backgroundColor: "green" }}
              onClick={(e) => handleSubmit()}
            >
              Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/*Generate Link modal*/}
      <Modal
        show={openLinkGen}
        onHide={handleCloseLinkGen}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Generate a Pay-by-Link Mamopay Link ({dataLink.query_id})</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container style={{ display: "flex" }}>
            <Col>
              <Row>
                <TextField
                  label="Title"
                  variant="outlined"
                  required
                  value={linkTitle}
                  fullWidth
                  onChange={(e) => {
                    setLinkTitleHandler(e.target.value);
                  }}
                  InputProps={{
                    spellCheck: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {linkTitle && (
                          <IconButton onClick={() => handleResetField("linkTitle")}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 45, // Adjust height as needed
                    },
                  }}
                />
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={linkDescription}
                  onChange={(e) => {
                    setLinkDescriptionHandler(e.target.value);
                  }}
                  InputProps={{
                    spellCheck: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {linkDescription && (
                          <IconButton onClick={() => handleResetField("linkDescription")}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 45, // Adjust height as needed
                    },
                  }}
                />
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <TextField
                  label="Amount"
                  variant="outlined"
                  fullWidth
                  required
                  value={linkAmount}
                  onChange={(e) => {
                    setLinkAmount(e.target.value);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 100,
                      step: 0.1,
                    },
                    spellCheck: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {linkAmount && (
                          <IconButton onClick={() => handleResetField("linkAmount")}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 45, // Adjust height as needed
                    },
                  }}
                />
              </Row>
              <div style={{ height: "7px" }} />
              <Row>
                <TextField
                  label="Processing Fee %"
                  variant="outlined"
                  fullWidth
                  value={linkFeePercent}
                  onChange={(e) => {
                    setLinkFeePercent(e.target.value);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 100,
                      step: 0.1,
                    },
                    spellCheck: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        {linkFeePercent && (
                          <IconButton onClick={() => handleResetField("linkFeePercent")}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 45, // Adjust height as needed
                    },
                  }}
                />
              </Row>
              <div style={{ height: "7px" }}></div>
              <Row>
                <Typography sx={{ display: "flex", justifyContent: "end" }}>
                  Final Amount :{" "}
                  {(linkAmount * (1 + linkFeePercent / 100)).toFixed(2) || 0}
                </Typography>
                <Divider
                  orientation="horizontal"
                  style={{
                    backgroundColor: "black",
                    height: "2px",
                    margin: "5px",
                    borderRadius: "2px",
                  }}
                  variant="middle"
                  flexItem
                />
              </Row>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography>Generated Link</Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography>{theLink}</Typography>
                </div>
                <div style={{ height: "7px" }}></div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    id="link_gen_send_wati"
                    endIcon={<SendOutlinedIcon />}
                    variant="contained"
                    onClick={handleSendWatiLink}
                    style={{ backgroundColor: "green", display: "none" }}
                  >
                    SEND TO CUSTOMER ON WATI
                  </Button>
                </div>
              </Row>
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              endIcon={<CancelOutlinedIcon />}
              variant="contained"
              onClick={handleCloseLinkGen}
            >
              Close
            </Button>
          </div>
          <div>
            <Button
              endIcon={<AddLinkIcon />}
              variant="contained"
              style={{ backgroundColor: "green" }}
              onClick={handleLinkGen}
            >
              Request
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/*make, modal, region etc adding modal*/}
      <Modal
        show={open_add_modal}
        onHide={handleCloseAddModal}
        size="sm"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicText">
              <Form.Control
                type="text"
                placeholder="Enter value"
                value={modal_data}
                onChange={(e) => set_modal_data(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            onClick={(e) => handleModalAdd(e, modalHeading)}
            style={{ backgroundColor: "green" }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/*Modal to edit settings*/}
      <SettingsModal
        open_settings={open_settings}
        handleCloseSettingsModal={handleCloseSettingsModal}
        statuses={queryStatusSet}
        save={save_settings}
      />

      <Modal
        show={openQuickReplies}
        onHide={handleCloseQuickReplies}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Use a Quick Response For Query ID : {currentQueryData.query_id} (
            {currentQueryData.cus_name} - {currentQueryData.phone})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Control
              type="text"
              placeholder="Search"
              className="mb-2"
              onChange={search_quickresponses}
            />
            <Tooltip
              title="Write a custom message and send to WATI."
              placement="top"
            >
              <Button
                variant="outline-secondary"
                className="mb-2"
                onClick={() => handleOpenQuickRepliesEdit(null)}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </div>
          <div
            style={{
              fontSize: "12px",
              color: "gray",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            <em>
              Total <b>{templateTemp.length}</b> templates.
            </em>
          </div>
          <div
            className="d-flex flex-wrap"
            style={{ height: "350px", overflowY: "auto" }}
          >
            {templateTemp.map((element, index) => (
              <Card
                className="m-2"
                style={{
                  width: "48%",
                  height: "250px",
                  overflowY: "auto",
                  backgroundColor: "#d2d9d7",
                }}
                key={index}
              >
                <Card.Body>
                  <Card.Title
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {element.name}
                    <Button
                      variant="outline-secondary"
                      className="mr-2"
                      onClick={() => handleOpenQuickRepliesEdit(element.id)}
                    >
                      <SendOutlinedIcon />
                    </Button>
                  </Card.Title>
                  <Card.Text>{element.text}</Card.Text>
                </Card.Body>
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <em>
                    Template has been used <b>{element.triggered}</b> times.
                  </em>
                </div>
              </Card>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuickReplies}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
        show={openQuickRepliesEdit}
        onHide={handleCloseQuickRepliesEdit}
      >
        <Modal.Header closeButton>
          <Modal.Title>Review message before sending</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* First Column: Table with Data */}
            <div
              className="col-md-4"
              style={{ height: "280px", overflowY: "auto" }}
            >
              <h5>Query Info</h5>
              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: "black",
                  height: "2px",
                  marginTop: "10px",
                  width: "100%",
                  borderRadius: "2px",
                }}
                variant="fullWidth"
                flexItem
              />
              <table>
                <tbody>
                  <tr>
                    <td>Query ID</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.query_id}</td>
                  </tr>
                  <tr>
                    <td>Part required</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.body_part}</td>
                  </tr>
                  <tr>
                    <td>Make</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.make}</td>
                  </tr>
                  <tr>
                    <td>Model</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.model}</td>
                  </tr>
                  <tr>
                    <td>Model Details</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.model_details}</td>
                  </tr>
                  <tr>
                    <td>VIN</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.chassis_no}</td>
                  </tr>
                  <tr>
                    <td>Cylinders</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.cylinder}</td>
                  </tr>
                  <tr>
                    <td>Region</td>
                    <td
                      style={{
                        width: "20px",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      :
                    </td>
                    <td>{currentQueryData.region}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Second Column: Textarea */}
            <div className="col-md-4">
              <h5>Message</h5>
              <Form.Control
                as="textarea"
                rows={10}
                placeholder="Type your message here..."
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
              />
            </div>

            {/* Third Column: Another Table with Data */}
            <div className="col-md-4">
              <h5>Customer Info</h5>
              <Divider
                orientation="horizontal"
                style={{
                  backgroundColor: "black",
                  height: "2px",
                  marginTop: "10px",
                  width: "100%",
                  borderRadius: "2px",
                }}
                variant="fullWidth"
                flexItem
              />
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>{currentQueryData.cus_name}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>:</td>
                    <td>{currentQueryData.phone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuickRepliesEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendWatiTemplate}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
}

export default Queryset;
