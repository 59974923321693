import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './components/Home/Landing';
import { AuthContext } from './components/AuthContext'
import { useState, useEffect, Suspense, } from 'react';
import axios from 'axios';
import NotFound from './components/Modals/NotFound';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Login from './pages/Login';
import Queryset from './pages/Queryset';
import WhatsApp from './pages/WhatsApp';
import Supplier from './pages/Supplier';
import QuerysetV2 from './pages/Querysetv2';
import WhatsappMessages from './pages/WhatsappMessages';
import Hrm from './pages/HRM';
import Test from './pages/Test';
import { API_DOMAIN } from './components/constants';
//dont remove this. This is needed to initialize the socket
import socket from './components/socket';
import Drawerr from './components/Drawer';
import GetTitle from './components/GetTitle';
import { CContainer, CSpinner } from '@coreui/react'
import DashboardV2 from './pages/DashboardV2';
import ChatApp from './components/WatiChatbox/BottomRightChatbox';

function App() {

  const [authState, setAuthState] = useState({
    username: "",
    id: -1,
    status: false,
    admin: false
  });

  useEffect(() => {
    axios.get(API_DOMAIN + "/auth/validate", {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      }
    }).then((res) => {
      if (res.data.error) {
        setAuthState({
          username: "",
          id: -1,
          status: false,
          admin: false
        });
      }
      else {
        setAuthState(res.data);
      }
    });

  }, []);

  return (
    <div className="App">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Router>
          <CContainer className="px-4" lg>

            {/* Only show ChatApp if user is authenticated */}
            {authState.status && <ChatApp />}
            <Suspense fallback={<CSpinner color="primary" />}>
              <Drawerr title={<GetTitle />}>
                <Routes>
                  {!authState.status ? (
                    <>
                      {/* No ChatApp on Login and Landing */}
                      <Route path="/" element={<Landing />} />
                      <Route path="/login" element={<Login />} />
                    </>
                  ) : (
                    <>
                      {/* ChatApp is now active in all these routes */}
                      <Route path="/" element={<DashboardV2 />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/queryset" element={<QuerysetV2 />} />
                      <Route path="/querysetv2" element={<QuerysetV2 />} />
                      <Route path="/whatsapp" element={<WhatsApp />} />
                      <Route path="/whatsapp_messages" element={<WhatsappMessages />} />
                      <Route path="/suppliers" element={<Supplier />} />
                      <Route path="/hrm" element={<Hrm />} />
                      <Route path="/test" element={<Test />} />
                      <Route path="*" element={<NotFound />} />
                    </>
                  )}
                </Routes>
              </Drawerr>
            </Suspense>
          </CContainer>
        </Router>
      </AuthContext.Provider>
    </div>

  );
}
//to add a new page
//1. create a new file in the pages folder
//2. import the new file here
//3. add a new Route in the Router
//4. add a new Route in the Drawer
//5. add the title text to b e returned for the new page in GetTitle.js
export default App;
